import React, { useContext, useEffect, useState } from "react";
import styles from "./InsertsCalib.module.scss";
import axios from "axios";

import Button from "../../UiComponents/Button";
import Loader from "../../UiComponents/loader/Loader";
import Success from "../../UiComponents/sucess/Success";
import inserts from "../../../pics/InsertPdf.png";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import {
  displayCalibValue,
  ticksToDate,
  ticksToDateYearMonthDay,
} from "../../calibration/functionDataSorted";

import NavContext from "../../../context/NavContext";
import Axios from "../../../_services/caller.service";
import { GreenLedSolo, RedLedSolo } from "../../Team/userCard/Led";
import { convertDateToTicks } from "../../../utils/handleDateTicks";
import download from "downloadjs";
import DropZone from "../../UiComponents/dropZone/DropZone";
import ModalNotGoodOupout from "./Modal/ModalNotGoodOupout";
import ModalComfirmImport from "./Modal/ModalComfirmImport";
import ModalConfirmRegisterNewValue from "./Modal/ModalConfirmRegisterNewValue";
import { decodedB64, encodedB64 } from "../../../CustomHooks/DecodedBase64";
import { toast } from "react-toastify";
import LoaderLogin from "../../UiComponents/loader/LoaderLogin";
const InsertsCalib = ({ cabId, setCabId, cabname }) => {
  const token = localStorage.getItem("jwt");
  // CALIBRATION

  const [calibGeneral, setCalibGeneral] = useState("");
  const [calibTonalLeft, setCalibTonalLeft] = useState([]);
  const [calibTonalRight, setCalibTonalRight] = useState([]);
  const [calibVocalLeft, setCalibVocalLeft] = useState("");
  const [calibVocalRight, setCalibVocalRight] = useState("");

  //SERIAL NUMBER INSERTS AND SOUNDCARD
  const [serialNumber, setSerialNumber] = useState("");
  const [serialSoundcardNumber, setSerialSoundcardNumber] = useState("");
  const [microphoneName, setMicrophoneName] = useState("");
  const [microphoneSerial, setMicrophoneSerial] = useState("");
  //DISPLAY
  const [displayInserts, setdisplayInserts] = useState(false);
  const [dateCalibration, setDateCalibration] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSucess] = useState(false);
  const [maj, setMaj] = useState(false);
  //LOADING

  const [loadingVoc, setloadingVoc] = useState(true);
  const [loadingTon, setloadingTon] = useState(true);
  const [loadingGen, setloadingGen] = useState(true);
  const [loadingCalibrationTonalForDate, setLoadingCalibrationTonalForDate] =
    useState(true);

  const [loadingAllData, setLoadingAllData] = useState(true);

  //IMPORT EXPORT FILE TXT
  const [selectedFile, setSelectedFile] = useState();
  const [modalConfirmImport, setModalConfirmImport] = useState(false);
  const [modalConfirmRegisterNewValue, setModalConfirmRegisterNewValue] =
    useState(false);
  const [modalNotGoodOutpout, setModalNotGoodOutpout] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [isCheckedDateUpdate, setIsCheckedDateUpdate] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  // CONTROLLER
  const controller = new AbortController();
  const signal = controller.signal;

  const frequencies = [
    "125Hz",
    "250Hz",
    "500Hz",
    "750Hz",
    "1Khz",
    "1.5Khz",
    "2Khz",
    "3Khz",
    "4Khz",
    "6KHz",
    "8KHz",
  ];

  useEffect(() => {
    if (cabId !== "") {
      const controller = new AbortController();
      const signal = controller.signal;

      const fetchGeneral = Axios.get(`/calibrationGeneral/${cabId}`, { signal })
        .then((response) => {
          setloadingGen(false);
          if (response.data.message === "aucune donnée") {
            setCalibGeneral("0");
          } else {
            const allValueHead = response.data.data;
            const valueGeneral = displayCalibValue(
              allValueHead,
              "Inserts_Value"
            );
            // n° de serie casque
            const serialNumberHeadphones = displayCalibValue(
              allValueHead,
              "Inserts_Serial"
            );
            setSerialNumber(decodedB64(serialNumberHeadphones));
            // n° de serie carte son casque
            const serialNumberHeadphonesSoundcard = displayCalibValue(
              allValueHead,
              "Inserts_Soundcard_Serial"
            );
            setSerialSoundcardNumber(
              decodedB64(serialNumberHeadphonesSoundcard)
            );
            const micsName = displayCalibValue(
              allValueHead,
              "Inserts_MicrophoneName"
            )[0];
            setMicrophoneName(decodedB64(micsName));
            const micsSerial = displayCalibValue(
              allValueHead,
              "Inserts_MicrophoneSerial"
            )[0];
            setMicrophoneSerial(micsSerial);
            if (valueGeneral.length >= 1) {
              setCalibGeneral(valueGeneral[0]);
            } else {
              setCalibGeneral("0");
            }
            const dateGeneral = displayCalibValue(
              allValueHead,
              "Inserts_Passed"
            )[0];
            return dateGeneral;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      const fetchTonal = Axios.get(`/calibrationTonal/${cabId}`, { signal })
        .then((response) => {
          setloadingTon(false);
          if (response.data.message === "aucune donnée") {
            setCalibTonalLeft([
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
            ]);
            setCalibTonalRight([
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
            ]);
          } else {
            const allValueHead = response.data.data;
            const valueTonal = displayCalibValue(allValueHead, "Inserts_Value");
            if (valueTonal.length >= 1) {
              const dataLeft = valueTonal[0].split(",").slice(0, 11);
              const dataRight = valueTonal[0].split(",").slice(11, 22);

              setCalibTonalLeft(dataLeft);
              setCalibTonalRight(dataRight);
            } else {
              setCalibTonalLeft([
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
              ]);
              setCalibTonalRight([
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
              ]);
            }
            const dateTonal = displayCalibValue(
              allValueHead,
              "Inserts_Passed"
            )[0];
            return dateTonal;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      const fetchVocal = Axios.get(`/calibrationVocal/${cabId}`, { signal })
        .then((response) => {
          setloadingVoc(false);
          if (response.data.message === "aucune donnée") {
            setCalibVocalLeft("0");
            setCalibVocalRight("0");
          } else {
            const allValueHead = response.data.data;
            const valueVocal = displayCalibValue(allValueHead, "Inserts_Value");
            if (valueVocal.length >= 1) {
              const dataLeft = valueVocal[0].split(",").slice(0, 1);
              const dataRight = valueVocal[0].split(",").slice(1, 2);
              setCalibVocalLeft(dataLeft);
              setCalibVocalRight(dataRight);
            } else {
              setCalibVocalLeft("0");
              setCalibVocalRight("0");
            }
            const dateVocal = displayCalibValue(
              allValueHead,
              "Inserts_Passed"
            )[0];
            return dateVocal;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      Promise.all([fetchGeneral, fetchTonal, fetchVocal]).then(
        ([dateGeneral, dateTonal, dateVocal]) => {
          // Vérifier si toutes les dates sont présentes
          const allDatesExist = dateGeneral && dateTonal && dateVocal;
        

          if (allDatesExist) {
            setDateCalibration(dateTonal); // Utiliser dateGeneral comme exemple
            setWarningMessage(""); // Pas de message d'avertissement nécessaire
            setLoadingCalibrationTonalForDate(false);
          } else {
            setDateCalibration("");
         
            setDateCalibration("");
            setCalibTonalLeft([
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
            ]);
            setCalibTonalRight([
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
            ]);
            setCalibGeneral("0");
            setCalibVocalLeft("0");
            setCalibVocalRight("0");
            setLoadingCalibrationTonalForDate(false);
            // Ne pas définir de message d'avertissement si toutes les dates sont absentes
          }
        }
      );

      return () => {
        controller.abort();
      };
    }
  }, [cabId, setCabId, token, maj]);

  //UPDATE CALIB TONAL STATE
  const updateFrequenciesValueLeft = (id, e) => {
    const newState = calibTonalLeft.map((obj, index) => {
      if (id === index) {
        const newValue = e.target.value; //valeur de l'input
        return newValue;
      }

      return obj;
    });

    setCalibTonalLeft(newState);
  };
  const updateFrequenciesValueRight = (id, e) => {
    const newState = calibTonalRight.map((obj, index) => {
      if (id === index) {
        const newValue = e.target.value; //valeur de l'input
        return newValue;
      }

      return obj;
    });

    setCalibTonalRight(newState);
  };

  const registerNewValueInserts = (params) => {
    setLoading(true);
    const serialNumberEncoded = encodedB64(serialNumber);
    const serialNumberSoundCardEncoded = encodedB64(serialSoundcardNumber);
    const date =
      isCheckedDateUpdate || !dateCalibration
        ? convertDateToTicks()
        : dateCalibration; // date now or date old
    //create catid if they don't exist in DB
    Axios.post(`/createCatid/${cabId}`)

      .then((response) => {
        toast.success(response.data.message);
        // if they exist or if they are created we register calib data
        Axios.put(`/newValueInsertsGeneral/${cabId}`, {
          data: { calibGeneral: calibGeneral, date: date },
        })

          .then((response) => {
            setLoading(false);
            setSucess(true);
            setTimeout(() => {
              setSucess(false);
            }, 1500);
          })
          .catch(function (error) {
            console.log(error);
          });
        Axios.put(`/newValueInsertsTonal/${cabId}`, {
          calibTonalLeft: calibTonalLeft,
          calibTonalRight: calibTonalRight,
          date: date,
        })

          .then((response) => {
            setLoading(false);
            setSucess(true);
            setTimeout(() => {
              setSucess(false);
            }, 1500);
          })
          .catch(function (error) {
            console.log(error);
          });
        Axios.put(`/newValueInsertsSerial/${cabId}`, {
          data: serialNumberEncoded,
        })

          .then((response) => {
            setLoading(false);
            setSucess(true);
            setTimeout(() => {
              setSucess(false);
            }, 1500);
          })
          .catch(function (error) {
            console.log(error);
          });
        Axios.put(`/newValueInsertsSoundcardSerial/${cabId}`, {
          data: serialNumberSoundCardEncoded,
        })

          .then((response) => {
            setLoading(false);
            setSucess(true);
            setTimeout(() => {
              setSucess(false);
            }, 1500);
          })
          .catch(function (error) {
            console.log(error);
          });
        Axios.put(`/newValueInsertsVocal/${cabId}`, {
          calibVocalLeft: calibVocalLeft,
          calibVocalRight: calibVocalRight,
          date: date,
        })

          .then((response) => {
            setSucess(true);
            setOverlay(false); // dans le cas d'import
            setModalConfirmImport(false); // dans le cas d'import
            setModalConfirmRegisterNewValue(false); // dans le cas de nouvelle valeur
            setMaj(!maj);
            toast.success("Toutes les données sont enregistrés");
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        if (error.response) {
          // Si une réponse avec un message d'erreur est reçue du backend
          const errorMessage = error.response.data.message;

          toast.error(errorMessage, {
            autoClose: false,
          });
        } else {
          toast.error(
            "une erreur s'est produite lors de la création des catid",
            {
              autoClose: false,
            }
          );
        }
      });
  };

  const checkHandler = () => {
    setIsCheckedDateUpdate(!isCheckedDateUpdate);
  };

  //IMPORT AND EXPORT CALIBRATION DATA
  const exportCalibDataInserts = async () => {
    const calibDate = ticksToDateYearMonthDay(dateCalibration);
    //Value Tonal
    const allValueTonal = calibTonalLeft.concat(calibTonalRight);

    //VocalValue
    const arrayVocalLeft = Array.isArray(calibVocalLeft)
      ? calibVocalLeft
      : [calibVocalLeft];
    const arrayVocalRight = Array.isArray(calibVocalRight)
      ? calibVocalRight
      : [calibVocalRight];
    const allValueVocal = arrayVocalLeft.concat(arrayVocalRight);

    const data = {
      Eoutpout: "Inserts",
      date: dateCalibration,
      general: calibGeneral,
      vocal: allValueVocal,
      tonal: allValueTonal,
      serialNumber: serialNumber, // Add serial number
      serialSoundcardNumber: serialSoundcardNumber, // Add soundcard serial number
    };

    const dataStr = JSON.stringify(data);
    const filename = `Inserts Calibration Data - ${serialNumber} - ${serialSoundcardNumber} - [${cabname}(${cabId})] - ${calibDate}.txt`;
    const filetype = "text/plain";

    // Download the file
    download(dataStr, filename, filetype);
  };

  const fileChangedHandler = (file) => {
    setSelectedFile(file);
  };

  useEffect(() => {
    if (selectedFile) {
      importCalibDataInserts();
    }
  }, [selectedFile]);

  const importCalibDataInserts = () => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      // The file's text will be printed here
      const text = e.target.result;

      const data = JSON.parse(text);

      if (data.Eoutpout !== "Inserts") {
        setModalNotGoodOutpout(true);
        setOverlay(true);
        return;
      }

      const insertsGeneral = data.general;
      const insertsVocalLeft = data.vocal.slice(0, 1);
      const insertsVocalRight = data.vocal.slice(1, 2);
      const insertsTonalLeft = data.tonal.slice(0, 11);
      const insertsTonalRight = data.tonal.slice(11, 22);
      const date = data.date;
      const importedSerialNumber = data.serialNumber || "";
      const importedSerialSoundcardNumber = data.serialSoundcardNumber || "";

      setCalibGeneral(insertsGeneral);
      setCalibTonalLeft(insertsTonalLeft);
      setCalibTonalRight(insertsTonalRight);
      setCalibVocalLeft(insertsVocalLeft);
      setCalibVocalRight(insertsVocalRight);
      setDateCalibration(date);
      setSerialNumber(importedSerialNumber); // Set the serial number
      setSerialSoundcardNumber(importedSerialSoundcardNumber); // Set the soundcard serial number
      setModalConfirmImport(true);
      setOverlay(true);
    };

    reader.readAsText(selectedFile);
  };

  // Surveiller tous les états de chargement
  useEffect(() => {
    if (
      !loadingGen &&
      !loadingTon &&
      !loadingVoc &&
      !loadingCalibrationTonalForDate
    ) {
      setLoadingAllData(false); // Tout est chargé
    }
  }, [loadingGen, loadingTon, loadingVoc, loadingCalibrationTonalForDate]);

  return (
    <>
      {loadingAllData && <LoaderLogin />}
      <div
        onClick={() => setdisplayInserts(!displayInserts)}
        className={styles.containerTitleBone}
      >
        <div className={styles.titleBone}>
          <span>Configuration Inserts</span>
          <img src={inserts} alt="" />
          {dateCalibration !== "" ? (
            <>
              <GreenLedSolo /> <span>{ticksToDate(dateCalibration)}</span>
            </>
          ) : (
            <>
              <RedLedSolo />
              <span>{warningMessage}</span>
            </>
          )}
        </div>
        {displayInserts ? (
          <IoIosArrowDropup className={styles.iconDropDown} />
        ) : (
          <IoIosArrowDropdown className={styles.iconDropDown} />
        )}
      </div>

      {displayInserts && (
        <div className={styles.containerBoneCalib}>
          {overlay && <div className={styles.overlay}></div>}
          <div className={styles.containerBtnExport}>
            <Button
              onPress={exportCalibDataInserts}
              styles={"btnExport"}
              text={"Export données de Calibration"}
            />
            <div className={styles.containerImport}>
              <div className={styles.containerInputImport}>
                <DropZone onFileUpload={fileChangedHandler} />
                <Button
                  onPress={() => {
                    setMaj(!maj);
                  }}
                  styles={"btnPrimary"}
                  text={"Reset"}
                />
              </div>
            </div>
          </div>

          {modalNotGoodOutpout && (
            <ModalNotGoodOupout
              setOverlay={setOverlay}
              setModalNotGoodOutpout={setModalNotGoodOutpout}
            />
          )}

          {modalConfirmImport && (
            <ModalComfirmImport
              setModalConfirmImport={setModalConfirmImport}
              setOverlay={setOverlay}
              isCheckedDateUpdate={isCheckedDateUpdate}
              checkHandler={checkHandler}
              setMaj={setMaj}
              maj={maj}
              registerNewValue={registerNewValueInserts}
            />
          )}

          <h2>Calibration des inserts</h2>
          <h3>
            {cabname}({cabId})
          </h3>
          <div className={styles.containerValue}>
            <div className={styles.general}>
              <h4>Correction Générale</h4>
              <input
                onChange={(e) => setCalibGeneral(e.target.value)}
                onWheel={() => document.activeElement.blur()}
                value={calibGeneral}
                type="number"
                step="0.1"
              />
            </div>

            <h4>Correction en Tonale</h4>
            <div className={styles.containerLeft}>
              <div className={styles.frequencies}>
                {frequencies.map((freq, index) => {
                  return <span key={index}>{freq}</span>;
                })}
              </div>

              <div className={styles.value}>
                <h4 className={styles.direction}>Gauche</h4>
                {calibTonalLeft.map((value, index) => {
                  return (
                    <input
                      onChange={(e) => {
                        updateFrequenciesValueLeft(index, e);
                      }}
                      onWheel={() => document.activeElement.blur()}
                      key={index}
                      type="number"
                      step="0.1"
                      value={value}
                    ></input>
                  );
                })}
              </div>
            </div>
            <div className={styles.containerRight}>
              <div className={styles.frequencies}>
                {frequencies.map((freq, index) => {
                  return <span key={index}>{freq}</span>;
                })}
              </div>

              <div className={styles.value}>
                <h4 className={styles.direction}>Droit</h4>
                {calibTonalRight.map((value, index) => {
                  return (
                    <input
                      onChange={(e) => {
                        updateFrequenciesValueRight(index, e);
                      }}
                      onWheel={() => document.activeElement.blur()}
                      key={index}
                      type="number"
                      step="0.1"
                      value={value}
                    ></input>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={styles.containerVocale}>
            <h4 className={styles.vocaleTitle}>Correction en Vocale</h4>

            <div className={styles.vocale}>
              <h4>Gauche</h4>
              <input
                onChange={(e) => setCalibVocalLeft(e.target.value)}
                onWheel={() => document.activeElement.blur()}
                type="number"
                step="0.1"
                value={calibVocalLeft}
              ></input>
            </div>

            <div className={styles.vocale}>
              <h4>Droit</h4>
              <input
                onChange={(e) => setCalibVocalRight(e.target.value)}
                onWheel={() => document.activeElement.blur()}
                type="number"
                step="0.1"
                value={calibVocalRight}
              ></input>
            </div>
          </div>
          {/* SERIAL */}
          <div className={styles.containerAllSerial}>
            <div className={styles.containerSerial}>
              <div className={styles.serial}>
                <h4>Nom du Micros</h4>

                <span>{microphoneName}</span>
              </div>
              <div className={styles.serial}>
                <h4>n° de série du Micro</h4>

                <span>{microphoneSerial}</span>
              </div>
              <div className={styles.serial}>
                <h4>n° de serie Inserts</h4>

                <input
                  onChange={(e) => setSerialNumber(e.target.value)}
                  type="text"
                  value={serialNumber}
                ></input>
              </div>
              <div className={styles.serial}>
                <h4>n° de série carte son</h4>
                <input
                  onChange={(e) => setSerialSoundcardNumber(e.target.value)}
                  type="text"
                  value={serialSoundcardNumber}
                ></input>
              </div>
            </div>
          </div>
          <div className={styles.containerBtn}>
            <div className={styles.useCurrentDate}>
              <input
                type="checkbox"
                checked={isCheckedDateUpdate}
                onChange={checkHandler}
              />
              <label htmlFor="">Mettre à jour avec la date du jour</label>
            </div>
            <Button
              onPress={() => {
                setOverlay(true);
                setModalConfirmRegisterNewValue(true);
              }}
              styles={"btnValidation"}
              text={"Enregistrer les nouvelles valeurs"}
            />
            {modalConfirmRegisterNewValue && (
              <ModalConfirmRegisterNewValue
                setOverlay={setOverlay}
                setModalConfirmRegisterNewValue={
                  setModalConfirmRegisterNewValue
                }
                isCheckedDateUpdate={isCheckedDateUpdate}
                checkHandler={checkHandler}
                registerNewValue={registerNewValueInserts}
              />
            )}
            {loading && <Loader />}
            {success && <Success success={"Données de calibration MAJ"} />}
          </div>
        </div>
      )}
    </>
  );
};

export default InsertsCalib;
