import React, { useState, useEffect, useContext, useRef } from "react";

import NavContext from "../../context/NavContext";
import SettingsCab from "./cabine/SettingsCab";

import SpeakersSettings from "./speakers/SpeakersSettings";

import HeadphoneCalib from "./calibrationData/HeadphoneCalib";
import SoundCard from "./soundCard/SoundCard";
import SearchUser from "./search/SearchUser";
import { useCabineContext } from "../../context/CabineContext";
import InfosUser from "./infos/InfosUser";
import styles from "./SuperAdmin.module.scss";
import SearchCabines from "./search/SearchCabines";

import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import cabineImg from "../../pics/cabine.png";

import InfosCabine from "./infos/InfosCabine";

import SelectConfigCabine from "./cabine/SelectConfigCabine";
import Axios from "../../_services/caller.service";
import { motion } from "framer-motion";
import { animatePage } from "../../animation/animationPage";
import InfosConnexion from "./infos/InfosConnexion";
import { useNavigate } from "react-router-dom";
import InsertsCalib from "./calibrationData/InsertsCalib";
import BoneCalib from "./calibrationData/BoneCalib";
import LoaderLogin from "../UiComponents/loader/LoaderLogin";

const SuperAdmin = () => {
  //CONTEXT
  const {
    ownerId,
    setOwnerId,

    setDisplayOwnerWithoutCabine,
  } = useContext(NavContext);
  const navigate = useNavigate();
  const { cabine } = useCabineContext(); //all cabine owner
  //USER
  const [allUser, setAllUser] = useState([]);
  const [infosUser, setInfosUser] = useState([]);
  const [infosLinkOwnerCab, setInfosLinkOwnerCab] = useState(""); //cabid for infos link at which owner
  const [userIsOwner, setUserIsOwner] = useState(false);
  const [inviteOwnerId, setInviteOwnerId] = useState(""); // stockes l'id du owner de l'invité
  const [ownerLink, setOwnerLink] = useState(""); // stockes l'id du owner qui est aussi user d'un autre owner

  const [userType, setUserType] = useState("");
  const [displaySelectConnexion, setDisplaySelectConnexion] = useState(false);
  const [displayConnectingUser, setDisplayConnectingUser] = useState(false);
  const [cabineAccess, setCabineAccess] = useState(true);
  const [
    displayConnectingUserWithCabineSearch,
    setDisplayConnectingUserWithCabineSearch,
  ] = useState(false);

  //ABO
  const [orders, setOrders] = useState([]);
  const [aboId, setAboId] = useState(""); // stockes la valeur du select de l'abonnement
  const [defaultAboId, setDefaultAboId] = useState(0); //  recupère l'abonnement qui est chargé en 1er pour affichage dans le select par default
  const [variationId, setVariationId] = useState(""); // Product Variation
  //CABINE
  const [cabId, setCabId] = useState("");
  const [cabinesByAbo, setCabinesByAbo] = useState([]); // only cabine by abo
  const [updateCabType, setUpdateCabType] = useState("");
  const [infosCabId, setInfosCabId] = useState(""); //cabid for infos link at which owner
  const [allCabines, setAllCabines] = useState([]);
  const [cabname, setCabname] = useState("");
  const [displaySettingCab, setDisplaySettingCab] = useState(true); // display settings cab
  const [loading, setLoading] = useState(false);
  const [reloadWooApi, setReloadWooApi] = useState(false);
  const [maj, setMaj] = useState(false); // MAJ lors de changement

  const [displayInfosOwnerLinkCab, setDisplayInfosOwnerLinkCab] =
    useState(false); //display infos owner link cabine
  const [displayInfosOwnerLinkUser, setDisplayInfosOwnerLinkUser] =
    useState(false); //display infos owner link cabine

  const [isSearchingCab, setIsSearchingCab] = useState(false);
  const [isSearchingUser, setIsSearchingUser] = useState(false);
  const [isSearchingSettingCab, setIsSearchingSettingCab] = useState(false);

  //CONTROLLER
  const controller = new AbortController();
  const signal = controller.signal;
  //pagination State

  //FILTER
  const [searchCab, setSearchCab] = useState(""); // search cabine by name
  const [searchUser, setSearchUser] = useState(""); // search user
  const [searchCabines, setSearchCabines] = useState("");
  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  let initialCabPerPage = 2;
  const [cabPerPage, setCabPerPage] = useState(initialCabPerPage);
  // //lEVEL LOG
  // const [levelLog, setLevelLog] = useState("");

  useEffect(() => {
    if (cabine !== "") {
      const defaultCabineValueOnPageLoad = cabine.sort((a, b) =>
        a.cabname.localeCompare(b.cabname)
      )[0].id;

      // SI le select de selection de recherche dans toutes les cabines
      //HS est select alors on met la cabine dans cabid
      //pour que quand on selectionne une cabine elle aparaisse dans le select des cabines/centre
      if (infosCabId !== "") {
        setCabId(infosCabId);
      } else {
        setCabId(defaultCabineValueOnPageLoad);
      }
    }
  }, [cabine]);

  // GET ALL USER
  useEffect(() => {
    Axios.get(`/getAllUserForSuperAdmin`, { signal })

      .then(function async(response) {
        setAllUser(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
    return () => {
      controller.abort();
    };
  }, []);

  // fonction avec le SELECT pour les user's
  const changeOwnerIdWithSelect = (e) => {
    const id = e.target.value;
    setOwnerId(id);
    setDisplayInfosOwnerLinkUser(true);
    setDisplayInfosOwnerLinkCab(false);
    setSearchUser("");
    setInfosCabId(""); // reset infos cabid
  };
  // fonction avec le SELECT pour les cabines
  const changeCabIdWithSelect = (e) => {
    const id = e.target.value;
    setInfosCabId(id);
    setCabId(id);
    setDisplayInfosOwnerLinkCab(true);
    setDisplayInfosOwnerLinkUser(false);
    setUserIsOwner(true);
    setSearchCab("");
  };

  // GET INFORMATION IF USER IS AN OWNER
  useEffect(() => {
    setDisplayConnectingUser(false);
    setCabineAccess(true);

    Axios.get(`/relationUser/${ownerId}`, { signal })
      .then(function async(response) {
        setDisplaySelectConnexion(true);

        const user = response.data.data.user;
        const ownerLink = response.data.data.owner; //les owner link
        const userTypeReceive = response.data.data.userType;
        setInfosUser(user);
        setOwnerLink(ownerLink);
        setUserType(userTypeReceive);

        if (displayConnectingUserWithCabineSearch) {
          setDisplayConnectingUser(true);
        }

        if (
          userTypeReceive !== "Owner et user d'un autre owner" &&
          userTypeReceive !== "Utilisateur des cabines d'un owner"
        ) {
          setDisplayConnectingUser(true);
        }

        if (userTypeReceive === "Aucun accès à une cabine") {
          setCabineAccess(false);
        }
      })
      .catch(function (error) {
        //  console.log(error);
      });
    return () => {
      controller.abort();
    };
  }, [ownerId]);

  // GET ALL CABINE AND SET A DEFAULT CABINE
  useEffect(() => {
    Axios.get(`/allCabines/`, { signal })

      .then(function async(response) {
        setAllCabines(response.data.data);
        //   setCabId(defaultCabineValueOnPageLoad)
      })
      .catch(function (error) {});
    return () => {
      controller.abort();
    };
  }, []);

  // GET OWNER LINK WITH A CABINE (owner or invite ...)
  useEffect(() => {
    if (infosCabId !== "") {
      Axios.get(`/relationCabine/${infosCabId}`, { signal })

        .then(function async(response) {
          setInfosLinkOwnerCab(response.data.data[0]);
          setInviteOwnerId(response.data.data[0].ID);
          setDisplayInfosOwnerLinkUser(true);
        })
        .catch(function (error) {});
      return () => {
        controller.abort();
      };
    }
  }, [infosCabId, setInfosCabId]);

  //Pagination method
  const indexOfLastPost = currentPage * cabPerPage;
  const indexOfFirstPost = indexOfLastPost - cabPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // get cabin name for components that need it
  useEffect(() => {
    const cabnameForComponent = cabine
      .filter((cab) => cab.id == cabId)
      .map((cab) => cab.cabname);

    setCabname(cabnameForComponent);
  }, [cabId, cabine]);

  return (
    <>
      <motion.div
        variants={animatePage}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={styles.containerSuperAdmin}
        onClick={() => {
          setIsSearchingCab(false);
          setIsSearchingUser(false);
        }}
      >
        {loading && <LoaderLogin />}
        {/* {displayConnectingUser && cabineAccess && (
            <InfosConnexion
              infosUser={infosUser}
              setOwnerId={setOwnerId}
              userIsOwner={userIsOwner}
              displayConnectingUser={displayConnectingUser}
              setDisplayConnectingUser={setDisplayConnectingUser}
              inviteOwnerId={inviteOwnerId}
              ownerLink={ownerLink}
              ownerId={ownerId}
            />
          )} */}

        <div className={styles.searchBar}>
          <SearchUser
            allUser={allUser}
            changeOwnerIdWithSelect={changeOwnerIdWithSelect}
            searchUser={searchUser}
            setSearchUser={setSearchUser}
            ownerId={ownerId}
            isSearchingUser={isSearchingUser}
            setIsSearchingUser={setIsSearchingUser}
          />
          <SearchCabines
            allCabines={allCabines}
            searchCabines={searchCabines}
            setSearchCabines={setSearchCabines}
            setInfosCabId={setInfosCabId}
            changeCabIdWithSelect={changeCabIdWithSelect}
            isSearchingCab={isSearchingCab}
            setIsSearchingCab={setIsSearchingCab}
          />
        </div>
        {!loading && !userIsOwner && displayInfosOwnerLinkUser && (
          <InfosUser
            setOwnerId={setOwnerId}
            userIsOwner={userIsOwner}
            inviteOwnerId={inviteOwnerId}
            infosUser={infosUser}
            ownerId={ownerId}
            ownerLink={ownerLink}
            userType={userType}
            displaySelectConnexion={displaySelectConnexion}
            setDisplaySelectConnexion={setDisplaySelectConnexion}
            setDisplayConnectingUser={setDisplayConnectingUser}
            displayConnectingUser={displayConnectingUser}
            cabineAccess={cabineAccess}
          />
        )}

        {!loading && displayInfosOwnerLinkCab && (
          <InfosCabine
            setOwnerId={setOwnerId}
            ownerName={infosLinkOwnerCab.display_name}
            login={infosLinkOwnerCab.user_login}
            email={infosLinkOwnerCab.user_email}
            date={infosLinkOwnerCab.user_registered}
            inviteOwnerId={inviteOwnerId}
            setdisplayInfosOwnerLinkCab={setDisplayInfosOwnerLinkCab}
            setDisplayConnectingUser={setDisplayConnectingUser}
            setDisplayConnectingUserWithCabineSearch={
              setDisplayConnectingUserWithCabineSearch
            }
          />
        )}

        <>
          {cabineAccess && (
            <SelectConfigCabine
              cabine={cabine}
              setCabId={setCabId}
              cabId={cabId}
              setIsSearchingSettingCab={setIsSearchingSettingCab}
              isSearchingSettingCab={isSearchingSettingCab}
            />
          )}

          {cabine &&
            cabineAccess &&
            cabine
              .filter((cab) => cab.id == cabId)

              .map((cab) => {
                return (
                  <SettingsCab
                    key={cab.id}
                    cabId={cab.id}
                    setCabId={setCabId}
                    cabType={cab.cabtype}
                    name={cab.cabname}
                    aboId={aboId}
                    maj={maj}
                    setMaj={setMaj}
                    updateCabType={updateCabType}
                    setUpdateCabType={setUpdateCabType}
                    cabinesByAbo={cabinesByAbo}
                    displaySettingCab={displaySettingCab}
                    setDisplaySettingCab={setDisplaySettingCab}
                  />
                );
              })}
          {cabineAccess && (
            <>
              <SoundCard cabId={cabId} setCabId={setCabId} cabname={cabname} />
              <div className={styles.containerSettingsCab}>
                <SpeakersSettings
                  aboId={aboId}
                  maj={maj}
                  setMaj={setMaj}
                  setCabId={setCabId}
                  cabId={cabId}
                  cabname={cabname}
                  cabine={cabine}
                />
                <BoneCalib
                  cabId={cabId}
                  setCabId={setCabId}
                  cabname={cabname}
                />
                <HeadphoneCalib
                  cabId={cabId}
                  setCabId={setCabId}
                  cabname={cabname}
                />
                <InsertsCalib
                  cabId={cabId}
                  setCabId={setCabId}
                  cabname={cabname}
                />
              </div>
            </>
          )}
        </>
      </motion.div>
    </>
  );
};

export default SuperAdmin;

//
