export function b64ToUint6(nChr) {
  return nChr > 64 && nChr < 91
    ? nChr - 65
    : nChr > 96 && nChr < 123
    ? nChr - 71
    : nChr > 47 && nChr < 58
    ? nChr + 4
    : nChr === 43
    ? 62
    : nChr === 47
    ? 63
    : 0;
}
export function JSBase64Decode(sBase64, nBlocksSize) {
  let sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, ""),
    nInLen = sB64Enc.length,
    nOutLen = nBlocksSize
      ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize
      : (nInLen * 3 + 1) >> 2,
    taBytes = new Uint8Array(nOutLen);

  for (
    let nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0;
    nInIdx < nInLen;
    nInIdx++
  ) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (6 * (3 - nMod4));
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
      }
      nUint24 = 0;
    }
  }

  let str = "";
  for (let i = 0; i < taBytes.length; i++)
    str += String.fromCharCode(taBytes[i]);
  return str;
}

// fonction decoded base 64 for character special ok

// export function decodedB64(str) {
//     if(str === "") return ""
//     if (str === undefined) return ""
//     return decodeURIComponent(escape(window.atob(str)));
//   }

export function decodedB64(str) {
  // console.log("Décodage de la chaîne:", str); // Log de la chaîne d'entrée

  if (str === "" || str === undefined) {
    // console.log("La chaîne est vide ou indéfinie, retour de la chaîne d'origine.");
    return str;
  }

  try {
    // Tentative de décodage
    const decoded = decodeURIComponent(escape(window.atob(str)));
    // console.log("Chaîne décodée:", decoded);

    // Vérification des caractères non affichables
    if (/[\x00-\x08\x0E-\x1F]/.test(decoded)) {
      console.log(
        "Décodage échoué : caractères non affichables détectés. Retour de la chaîne d'origine."
      );
      console.log(str);

      return str;
    }

    return decoded;
  } catch (e) {
    console.error("Erreur lors du décodage base64:", e.message);
    // Retourne la chaîne d'origine si une erreur est capturée
    return str;
  }
}

export function encodedB64(str) {
  if (str === "") return "";
  if (str === undefined) return "";
  return window.btoa(unescape(encodeURIComponent(str)));
}
