import React, { useEffect, useState } from "react";
import Axios from "../../_services/caller.service";
import styles from "./WordsLicence.module.scss";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import Modal from "../UiComponents/modals/Modal";
import useModal from "../../hooks/useModal";
import { IoMdAddCircleOutline } from "react-icons/io";
import Button from "../UiComponents/Button";
import InputForms from "../UiComponents/forms/InputForms";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid"; // Import uuid library for generating GUIDs

// Region codes for major countries
const regionOptions = [
  { value: "FR", label: "France" },
  { value: "DE", label: "Germany" },
  { value: "ES", label: "Spain" },
  { value: "IT", label: "Italy" },
  { value: "US", label: "United States" },
  { value: "CA", label: "Canada" },
  { value: "UK", label: "United Kingdom" },
  { value: "JP", label: "Japan" },
  { value: "CN", label: "China" },
  { value: "IN", label: "India" },
];

const WordsLicence = () => {
  const [editRowId, setEditRowId] = useState(null);
  const [formData, setFormData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [numberOfLines, setNumberOfLines] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [maj, setMaj] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(regionOptions[0]); // Default to first option

  const { isShowing, toggle } = useModal();
  const columnOrder = [
    "ID",
    "LicenceKey",
    "Region Code",
    "CompteHS",
    "cabids",
    "Notes",
  ];

  // GET ALL WORDS LICENCES
  useEffect(() => {
    Axios.get("/wordsLicence")
      .then((response) => {
        setTableData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [maj]);

  // EDIT ROW
  const handleEdit = (rowId, rowData) => {
    setEditRowId(rowId);
    const currentRowData = tableData.find((row) => row.ID === rowData.ID);
    setFormData({ ...currentRowData });
  };

  // SAVE EDITED ROW
  const handleSave = () => {
    const data = formData; // Modified data
    const id = data.ID; // Row ID

    Axios.put(`/updateWordsLicence/${id}`, data)
      .then((response) => {
        const updatedDataList = tableData.map((item) =>
          item.ID === id ? { ...item, ...data } : item
        );
        setTableData(updatedDataList);
        toast.success("Modification effectuée avec succès");
        setEditRowId(null);
        setMaj(!maj);
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour", error);
        toast.error("Erreur lors de la mise à jour");
      });
  };

  // COPY TO CLIPBOARD FUNCTION
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast("Copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  // GENERATE NEW ROWS WITH UUIDs
  const submitLines = () => {
    const linesToCreate = Array.from({ length: numberOfLines }, () => ({
      LicenceKey: uuidv4(), // Generate UUID for each line
      RegionCode: selectedRegion.value, // Use selected region code
      CompteHS: "", // Set default or fetch if needed
      cabids: "", // Set default or fetch if needed
      Notes: "",
    }));

    Axios.post("/createMultipleWordsLicences", { lines: linesToCreate })
      .then((response) => {
        const updatedDataList = [...tableData, ...response.data.newLines];
        setTableData(updatedDataList);
        toast.success("Les lignes ont été créées avec succès");
        toggle();
      })
      .catch((error) => {
        console.error("Erreur lors de la création des lignes", error);
        toast.error("Erreur lors de la création des lignes");
      });
  };

  // HANDLE INPUT CHANGE
  const handleInputChange = (e, key) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const filteredData = tableData.filter((row) =>
    row.LicenceKey.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.containerWordsLicence}>
      <div className={styles.containersearchAndButton}>
        <InputForms
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <IoMdAddCircleOutline
          className={styles.iconAddNewMics}
          onClick={() => {
            toggle();
          }}
          style={{ color: "#6d5dfc", fontSize: "50px", cursor: "pointer" }}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            {columnOrder.map((colKey) => (
              <th key={colKey} className={styles.th}>
                {colKey}
              </th>
            ))}
            <th className={styles.th}>Actions</th>
          </tr>
        </thead>

        <tbody>
          {filteredData.map((row, index) => (
            <tr key={index}>
              {columnOrder.map((colKey) => {
                const value = row[colKey];

                if (["LicenceKey"].includes(colKey) && editRowId !== index) {
                  return (
                    <td key={colKey} className={styles.td}>
                      <div>
                        {value}
                        <FiCopy
                          onClick={() => copyToClipboard(value)}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                      </div>
                    </td>
                  );
                }

                // ID column display
                if (colKey === "ID") {
                  return (
                    <td key={colKey} className={styles.td}>
                      {value}
                    </td>
                  );
                }

                // Handle edit mode
                return editRowId === index ? (
                  <td key={colKey} className={styles.td}>
                    <input
                      type="text"
                      className={styles.input}
                      value={formData[colKey]}
                      onChange={(e) => handleInputChange(e, colKey)}
                    />
                  </td>
                ) : (
                  <td key={colKey} className={styles.td}>
                    {value}
                  </td>
                );
              })}
              <td className={styles.td}>
                {editRowId === index ? (
                  <div className={styles.containerBtn}>
                    <button
                      className={styles.button}
                      onClick={() => handleSave(index)}
                    >
                      Save
                    </button>
                    <button
                      className={styles.buttonCancel}
                      onClick={() => {
                        setEditRowId(null);
                        setFormData({});
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button
                    className={styles.button}
                    onClick={() => handleEdit(index, row)}
                  >
                    Edit
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal isShowing={isShowing} hide={toggle}>
        <div className={styles.containerModal}>
          <InputForms
            type="number"
            value={numberOfLines}
            onChange={(e) => setNumberOfLines(e.target.value)}
            placeholder="Nombre de lignes"
          />
          <Select
            options={regionOptions}
            value={selectedRegion}
            onChange={setSelectedRegion}
            className={styles.selectRegion}
            placeholder="Select Region"
          />
          <Button
            onPress={submitLines}
            text={"Générer les lignes"}
            styles={"btnValidation"}
          />
        </div>
      </Modal>
    </div>
  );
};

export default WordsLicence;
