import React, { useEffect, useState } from "react";
import styles from "./SpeakersSettings.module.scss";
import axios from "axios";
import Button from "../../UiComponents/Button";
import Loader from "../../UiComponents/loader/Loader";
import Success from "../../UiComponents/sucess/Success";
import SpeakersGraph from "./SpeakersGraph";
import {
  displayAngleValue,
  displayCalibValue,
  ticksToDate,
} from "../../calibration/functionDataSorted";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import speakers from "../../../pics/speakersPdf.png";
import Axios from "../../../_services/caller.service";
import { GreenLedSolo, RedLedSolo } from "../../Team/userCard/Led";
//IMPORT EXPORT
import download from "downloadjs";
import ModalConfirmRegisterNewValue from "../calibrationData/Modal/ModalConfirmRegisterNewValue";
import DropZone from "../../UiComponents/dropZone/DropZone";
import ModalNotGoodOupout from "../calibrationData/Modal/ModalNotGoodOupout";
import ModalComfirmImport from "../calibrationData/Modal/ModalComfirmImport";
import { convertDateToTicks } from "../../../utils/handleDateTicks";
import { emptyDataAngle } from "./emptyDataAngle";
import { toast } from "react-toastify";
import LoaderLogin from "../../UiComponents/loader/LoaderLogin";
import { decodedB64 } from "../../../CustomHooks/DecodedBase64";
const SpeakersSettings = ({ cabine, cabId, setCabId, cabname }) => {
  //ANGLE DATA
  const [infosAngle, setInfosAngle] = useState([]);

  //VALUE DATA
  const [speakersGeneral, setSpeakersGeneral] = useState({});
  const [speakersVocal, setSpeakersVocal] = useState({});
  const [speakersTonal, setSpeakersTonal] = useState({});
  //DISPLAY
  const [displaySpeakers, setDisplaySpeakers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSucess] = useState(false);
  const [dateCalibration, setDateCalibration] = useState("");
  const [maj, setMaj] = useState(false);
  //SERIAL AND NAME MICS
  const [microphoneName, setMicrophoneName] = useState("");
  const [microphoneSerial, setMicrophoneSerial] = useState("");
  //IMPORT EXPORT FILE TXT
  const [selectedFile, setSelectedFile] = useState();
  const [modalConfirmImport, setModalConfirmImport] = useState(false);
  const [modalConfirmRegisterNewValue, setModalConfirmRegisterNewValue] =
    useState(false);
  const [modalNotGoodOutpout, setModalNotGoodOutpout] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [isCheckedDateUpdate, setIsCheckedDateUpdate] = useState(false);
  //LOADING
  const [loadinginfosAngleSettings, setLoadinginfosAngleSettings] =
    useState(true);
  const [loadingVoc, setloadingVoc] = useState(true);
  const [loadingTon, setloadingTon] = useState(true);
  const [loadingGen, setloadingGen] = useState(true);
  const [loadingCalibrationTonalForDate, setLoadingCalibrationTonalForDate] =
    useState(true);

  const [loadingAllData, setLoadingAllData] = useState(true);

  //WARNING
  const [warningMessage, setWarningMessage] = useState("");

  //CONTROLLER
  const controller = new AbortController();
  const signal = controller.signal;

  //GET ANGLE INFOS
  useEffect(() => {
    if (cabId !== "") {
      Axios.get(`/infosAngleSettings/${cabId}`)

        .then(async (response) => {
          setLoadinginfosAngleSettings(false);
          if (response.data.data.length === 0) {
            setInfosAngle(emptyDataAngle);
          } else {
            setInfosAngle(response.data.data);
          }
        })

        .catch(function (error) {
          console.log(error);
        });
    }
  }, [cabId, setCabId, maj]);

  //GET CALIBRATION INFOS

  useEffect(() => {
    const speakerNames = [
      "Gauche",
      "Droit",
      "Center",
      "Surround G",
      "Surround D",
      "Back G",
      "Back D",
    ]; // You can extend this array

    const fetchDataTonal = async () => {
      let dateTonal = "";
      try {
        const response = await Axios.get(`/calibrationTonal/${cabId}`);

        if (response.data.message === "aucune donnée") {
          let speakerObj = {};
          speakerNames.forEach((name) => {
            speakerObj[name] = Array(11).fill("0");
          });
          setSpeakersTonal(speakerObj);
        } else {
          const allValueSpeakers = response.data.data;
          const valueT = displayCalibValue(allValueSpeakers, "Speakers_Value");
          dateTonal = displayCalibValue(allValueSpeakers, "Speakers_Passed");
          dateTonal = dateTonal[0];
          const values = valueT[0].split(",");
          let speakerObj = {};
          for (let i = 0; i < values.length; i += 11) {
            const speakerIndex = i / 11;
            if (speakerNames[speakerIndex]) {
              speakerObj[speakerNames[speakerIndex]] = values.slice(i, i + 11);
            }
          }

          setSpeakersTonal(speakerObj);

          const micsName = displayCalibValue(
            allValueSpeakers,
            "Speakers_MicrophoneName"
          )[0];
          setMicrophoneName(decodedB64(micsName));
          const micsSerial = displayCalibValue(
            allValueSpeakers,
            "Speakers_MicrophoneSerial"
          )[0];
          setMicrophoneSerial(micsSerial);
        }
      } catch (error) {
      } finally {
        setloadingTon(false);
      }

      return dateTonal;
    };
    const fetchDataVocal = async () => {
      let dateVocal = "";
      try {
        const response = await Axios.get(`/calibrationVocal/${cabId}`);

        if (response.data.message === "aucune donnée") {
          let speakerObj = {};
          speakerNames.forEach((name) => {
            speakerObj[name] = Array(1).fill("0");
          });
          setSpeakersVocal(speakerObj);
        } else {
          const allValueSpeakers = response.data.data;
          const valueT = displayCalibValue(allValueSpeakers, "Speakers_Value");
          dateVocal = displayCalibValue(allValueSpeakers, "Speakers_Passed");
          dateVocal = dateVocal[0];
          const values = valueT[0].split(",");
          let speakerObj = {};
          for (let i = 0; i < values.length; i += 1) {
            const speakerIndex = i / 1;
            if (speakerNames[speakerIndex]) {
              speakerObj[speakerNames[speakerIndex]] = values.slice(i, i + 1);
            }
          }

          setSpeakersVocal(speakerObj);
        }
      } catch (error) {
      } finally {
        setloadingVoc(false);
      }

      return dateVocal;
    };
    const fetchDataGeneral = async () => {
      let dateGeneral = "";
      try {
        const response = await Axios.get(`/calibrationGeneral/${cabId}`);

        if (response.data.message === "aucune donnée") {
          setSpeakersGeneral("0");
        } else {
          const allValueSpeakers = response.data.data;
          const valueT = displayCalibValue(allValueSpeakers, "Speakers_Value");
          dateGeneral = displayCalibValue(allValueSpeakers, "Speakers_Passed");
          dateGeneral = dateGeneral[0];
          const values = valueT[0];

          setSpeakersGeneral(values);
        }
      } catch (error) {
      } finally {
        setloadingGen(false);
      }

      return dateGeneral;
    };

    if (cabId !== "") {
      Promise.all([
        fetchDataVocal(),
        fetchDataTonal(),
        fetchDataGeneral(),
      ]).then(([dateVocal, dateTonal, dateGeneral]) => {
        const allDatesExist = dateGeneral && dateTonal && dateVocal;

        if (allDatesExist) {
          setDateCalibration(dateTonal); // Utiliser dateGeneral comme exemple
          setWarningMessage(""); // Pas de message d'avertissement nécessaire
          setLoadingCalibrationTonalForDate(false);
        } else {
 
          setDateCalibration("");
      
          setLoadingCalibrationTonalForDate(false);
          let speakerObj = {};
          speakerNames.forEach((name) => {
            speakerObj[name] = Array(11).fill("0");
          });
          setSpeakersTonal(speakerObj);
          let speakerObjVocal = {};
          speakerNames.forEach((name) => {
            speakerObjVocal[name] = Array(1).fill("0");
          });
          setSpeakersVocal(speakerObjVocal);
          setSpeakersGeneral("0");
        }

      });
    }
  }, [cabId, setCabId, maj, setMaj]);

  const handleInputChangeTonal = (event, key, index) => {
    const value = event.target.value;

    setSpeakersTonal((prevSpeakers) => ({
      ...prevSpeakers,
      [key]: prevSpeakers[key].map((item, i) => (i === index ? value : item)),
    }));
  };

  const handleInputChangeVocal = (event, key, index) => {
    const value = event.target.value;

    setSpeakersVocal((prevSpeakers) => ({
      ...prevSpeakers,
      [key]: prevSpeakers[key].map((item, i) => (i === index ? value : item)),
    }));
  };

  //REGISTER NEW VALUE SPEAKERS

  const registerNewValueSpeakers = async (params) => {
    try {
      setLoading(true);

      const date =
        isCheckedDateUpdate || !dateCalibration
          ? convertDateToTicks()
          : dateCalibration; // date now or date old

      // Create catid if they don't exist in DB
      const responseCreateCatid = await Axios.post(`/createCatid/${cabId}`);
      await Axios.post(`/createCatidSpeakersSetup/${cabId}`);

      //  Register calib data
      await Axios.put(`/newValueSpeakersGeneral/${cabId}`, {
        data: { calibGeneral: speakersGeneral, date: date },
      });
      await Axios.put(`/newValueSpeakersTonal/${cabId}`, {
        calibTonal: speakersTonal,
        date: date,
      });
      await Axios.put(`/newValueSpeakersVocal/${cabId}`, {
        calibVocal: speakersVocal,
        date: date,
      });
      await Axios.put(`/newValueSpeakersAngle/${cabId}`, {
        data: infosAngle,
      });

      setOverlay(false); // dans le cas d'import
      setModalConfirmImport(false); // dans le cas d'import
      setModalConfirmRegisterNewValue(false); // dans le cas de nouvelle valeur
      setMaj(!maj);
      setLoading(false);

      toast.success("Les données ont bien été enregistrer");

      toast.success(responseCreateCatid.data.message);
    } catch (error) {
      toast.error("une erreur s'est produite", {
        autoClose: false,
      });
    }
  };

  //IMPORT AND EXPORT CALIBRATION DATA

  const checkHandler = () => {
    setIsCheckedDateUpdate(!isCheckedDateUpdate);
  };

  const exportCalibDataSpeakers = async () => {
    const calibDate = ticksToDate(dateCalibration);
    console.log(speakersVocal);
    // Create a TXT file
    const data = {
      Eoutpout: "Speakers",
      date: dateCalibration,
      general: speakersGeneral,
      vocal: speakersVocal,
      tonal: speakersTonal,
      angle: infosAngle,
    };

    const dataStr = JSON.stringify(data);
    const filename = `Certicat Calibration CL [${cabname}${cabId}] ${calibDate}.txt`;
    const filetype = "text/plain";

    // Download the file
    download(dataStr, filename, filetype);
  };

  const fileChangedHandler = (file) => {
    setSelectedFile(file);
  };

  useEffect(() => {
    if (selectedFile) {
      importCalibDataSpeakers();
    }
  }, [selectedFile]);

  const importCalibDataSpeakers = () => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      // The file's text will be printed here
      const text = e.target.result;

      const data = JSON.parse(text);

      if (data.Eoutpout !== "Speakers") {
        setModalNotGoodOutpout(true);
        setOverlay(true);
        return;
      }

      //VOIR ICI POUR METTRE LA DATA DANS LES STATES
      const angle = data.angle;
      const general = data.general;
      const vocal = data.vocal;
      const tonal = data.tonal;
      const date = data.date;

      setInfosAngle(angle);
      setSpeakersGeneral(general);
      setSpeakersVocal(vocal);
      setSpeakersTonal(tonal);
      setDateCalibration(date);
      setModalConfirmImport(true);
      setOverlay(true);
    };

    reader.readAsText(selectedFile);
  };

  const frequencies = [
    "125Hz",
    "250Hz",
    "500Hz",
    "750Hz",
    "1Khz",
    "1.5Khz",
    "2Khz",
    "3Khz",
    "4Khz",
    "6KHz",
    "8KHz",
  ];

  // Surveiller tous les états de chargement
  useEffect(() => {
    if (
      !loadinginfosAngleSettings &&
      !loadingGen &&
      !loadingTon &&
      !loadingVoc &&
      !loadingCalibrationTonalForDate
    ) {
      setLoadingAllData(false); // Tout est chargé
    }
  }, [
    loadinginfosAngleSettings,
    loadingGen,
    loadingTon,
    loadingVoc,
    loadingCalibrationTonalForDate,
  ]);

  return (
    <>
      {loadingAllData && <LoaderLogin />}
      <div
        onClick={() => setDisplaySpeakers(!displaySpeakers)}
        className={styles.containerTitleSpeakers}
      >
        <div className={styles.titleSpeakers}>
          <span>Configuration CL</span>

          <img src={speakers} alt="" />
          {dateCalibration !== "" ? (
            <>
              <GreenLedSolo /> <span>{ticksToDate(dateCalibration)}</span>
            </>
          ) : (
            <>
              <RedLedSolo />
              <span>{warningMessage}</span>
            </>
          )}
        </div>
        {displaySpeakers ? (
          <IoIosArrowDropup className={styles.iconDropDown} />
        ) : (
          <IoIosArrowDropdown className={styles.iconDropDown} />
        )}
      </div>

      {displaySpeakers && (
        <>
          <div className={styles.containerSpeakers}>
            {overlay && <div className={styles.overlay}></div>}
            <div className={styles.containerBtnExport}>
              <Button
                onPress={exportCalibDataSpeakers}
                styles={"btnExport"}
                text={"Export données de Calibration"}
              />
              <div className={styles.containerImport}>
                <div className={styles.containerInputImport}>
                  <DropZone onFileUpload={fileChangedHandler} />
                  <Button
                    onPress={() => {
                      setMaj(!maj);
                    }}
                    styles={"btnPrimary"}
                    text={"Reset"}
                  />
                </div>
              </div>
            </div>

            <h3>
              {cabname}({cabId})
            </h3>
            {modalNotGoodOutpout && (
              <ModalNotGoodOupout
                setOverlay={setOverlay}
                setModalNotGoodOutpout={setModalNotGoodOutpout}
              />
            )}

            {modalConfirmImport && (
              <ModalComfirmImport
                setModalConfirmImport={setModalConfirmImport}
                setOverlay={setOverlay}
                isCheckedDateUpdate={isCheckedDateUpdate}
                checkHandler={checkHandler}
                setMaj={setMaj}
                maj={maj}
                registerNewValue={registerNewValueSpeakers}
              />
            )}
            <div className={styles.containerAllValueAngle}>
              {infosAngle.length >= 1 &&
                infosAngle.slice(1).map((angle, index) => {
                  return (
                    <div key={index} className={styles.containerValueAngle}>
                      <span>{angle.fname}</span>

                      <input
                        key={angle.fname}
                        onChange={(e) => {
                          const newInfosAngle = infosAngle.map((item) =>
                            item.fname === angle.fname
                              ? { ...item, fval: e.target.value }
                              : item
                          );
                          setInfosAngle(newInfosAngle);
                        }}
                        className={styles.rsRange}
                        type="number"
                        step="1"
                        value={angle.fval}
                        min="-180"
                        max="180"
                      />
                    </div>
                  );
                })}
            </div>
            <div>
              <SpeakersGraph
                data={infosAngle}
                numberSpeakers={displayAngleValue(
                  infosAngle,
                  "FMOD_Speaker_Mode"
                )}
              />
            </div>

            {loading && <Loader />}
            {success && (
              <Success success={"Les valeurs ont bien été enregistrée"} />
            )}

            <div className={styles.containerInputGeneral}>
              <h4>Correction Générale</h4>
              <input
                type="number"
                step={0.1}
                value={speakersGeneral}
                onChange={(e) => setSpeakersGeneral(e.target.value)}
              />
            </div>

            {/* tonal */}
            <h4>Correction en Tonale</h4>
            {Object.keys(speakersTonal).map((key) => (
              <div className={styles.containerValueTonal} key={key}>
                <span>{key}</span>
                {speakersTonal[key].map((value, i) => (
                  <div className={styles.containerInputTonal} key={i}>
                    <label>{frequencies[i]}</label>
                    <input
                      type="number"
                      step={0.1}
                      value={value}
                      onChange={(event) =>
                        handleInputChangeTonal(event, key, i)
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
            {/* vocal */}
            <h4>Correction en Vocale</h4>
            <div className={styles.blocVocal}>
              {Object.keys(speakersVocal).map((key) => (
                <div className={styles.containerValueVocal} key={key}>
                  <span>{key}</span>
                  {speakersVocal[key].map((value, i) => (
                    <input
                      key={i}
                      type="number"
                      step={0.1}
                      value={value}
                      onChange={(event) =>
                        handleInputChangeVocal(event, key, i)
                      }
                    />
                  ))}
                </div>
              ))}
            </div>
            <div className={styles.containerAllSerial}>
              <div className={styles.containerSerial}>
                <div className={styles.serial}>
                  <h4>Nom du Micro</h4>

                  <span>{microphoneName}</span>
                </div>
                <div className={styles.serial}>
                  <h4>n° de série du Micro</h4>

                  <span>{microphoneSerial}</span>
                </div>
              </div>
            </div>
            <div className={styles.containerBtnRegisterNewValue}>
              <div className={styles.useCurrentDate}>
                <input
                  type="checkbox"
                  checked={isCheckedDateUpdate}
                  onChange={checkHandler}
                />
                <label htmlFor="">Mettre à jour avec la date du jour</label>
              </div>
              <Button
                onPress={() => {
                  setOverlay(true);
                  setModalConfirmRegisterNewValue(true);
                }}
                styles={"btnValidation"}
                text={"Enregistrer les nouvelles valeurs"}
              />
            </div>

            {modalConfirmRegisterNewValue && (
              <ModalConfirmRegisterNewValue
                setOverlay={setOverlay}
                setModalConfirmRegisterNewValue={
                  setModalConfirmRegisterNewValue
                }
                isCheckedDateUpdate={isCheckedDateUpdate}
                checkHandler={checkHandler}
                registerNewValue={registerNewValueSpeakers}
              />
            )}
            <div className={styles.success}>
              {success && (
                <Success success={"Les valeurs ont bien été enregistrée"} />
              )}
              {loading && <Loader />}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SpeakersSettings;
